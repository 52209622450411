








import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PagesIndex',

  components: {
    PagesCoreFooter: () => import('./components/core/Footer.vue'),
    PagesCoreView: () => import('./components/core/View.vue'),
  },

})
